import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store/store';
import Layout from '../layout';

const ProviderContainer = ({ children, pageType }) => {
  return (
    <Layout pageType={pageType}>
      <Provider store={store}>
        {children}
      </Provider>
    </Layout>
  );
};

export default ProviderContainer;
