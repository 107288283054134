import React, { useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Search from '../components/Search';
import ProviderContainer from '../containers/ProviderContainer';

import { useGaEvent } from '../hooks/hooks.ga-events';

const SearchResults = () => {
  return (
    <ProviderContainer pageType="Search Result Page">
      <SearchResultsHeaderWithRedux />
    </ProviderContainer>
  );
};

const SearchResultsHeader = ({ value }) => {
  const googleSearch = useRef(null);

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cse.google.com/cse.js?cx=003786810031903986149:nrlqx_yd-hq';
    script.async = true;
    document.body.appendChild(script);

    setTimeout(() => {
      if (googleSearch.current) {
        const googleInput = document.getElementById('gsc-i-id1');
        if (googleInput) {
          googleInput.value = value;
        }
      }
      const buttonSearch = document.getElementsByClassName('gsc-search-button gsc-search-button-v2')[0];
      if (buttonSearch) {
        buttonSearch.click();
      }
      useGaEvent('Internal Search', value);
    }, 1500);
  }, []);

  const handleSubmit = (value) => {
    if (googleSearch.current) {
      const googleInput = document.getElementById('gsc-i-id1');
      if (googleInput) {
        googleInput.value = value;
      }
    }
    const buttonSearch = document.getElementsByClassName('gsc-search-button gsc-search-button-v2')[0];
    if (buttonSearch) {
      buttonSearch.click();
    }
    useGaEvent('Internal Search', value);
  };

  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <script type="text/javascript" async="" src="https://cse.google.com/cse.js?cx=003786810031903986149:nrlqx_yd-hq" />
      </Helmet>
      <section className="search-results">
        <div className="search-results__search">
          <Search submitCallback={handleSubmit} />
        </div>
        <div className="search-results__wrapper">
          <div ref={googleSearch} className="gcse-search" />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    value: state.search.value
  };
};

const SearchResultsHeaderWithRedux = connect(mapStateToProps, null)(SearchResultsHeader);

export default SearchResults;
